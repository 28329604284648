import "vite/modulepreload-polyfill";


// Function to set the theme based on the system preference or local storage
function setTheme() {
	const htmlElement = document.documentElement;
	const storedTheme = localStorage.getItem('theme');
	
	if (storedTheme) {
		// If a theme is stored in local storage, use that
		htmlElement.classList.add(storedTheme);
	} else {
		// Otherwise, use the system preference
		const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
		if (prefersDarkScheme) {
			htmlElement.classList.add('dark');
		} else {
			htmlElement.classList.add('light');
		}
	}

	plausible("Change Theme");
}

// Function to swap between dark and light themes
function swapTheme() {
const htmlElement = document.documentElement;

if (htmlElement.classList.contains('dark')) {
	htmlElement.classList.remove('dark');
	htmlElement.classList.add('light');
	localStorage.setItem('theme', 'light');
} else {
	htmlElement.classList.remove('light');
	htmlElement.classList.add('dark');
	localStorage.setItem('theme', 'dark');
}
}

// Set the initial theme on page load
setTheme();

// Example usage: Attach the swapTheme function to a button click event
document.getElementById('theme-toggle').addEventListener('click', swapTheme);

// Add the 'loaded' class to the html element on load
window.onload = function() {
	document.documentElement.classList.add('loaded');
};


//###### Log Get in Touch clicks


function clickCTA() {
	plausible("Get In Touch");
}

document.addEventListener('DOMContentLoaded', function() {
	document.querySelectorAll('.cta').forEach(function(element) {
		element.addEventListener('click', function() {
			clickCTA();
		});
	});
});





  
//###### Import global styles

import "@css/main.css";
